import React, { useState } from "react"
import "./FooterStyle.scss"
import { Cookies } from "react-cookie-consent"
import Logo from "../../assets/images/logo.png"
import { Link } from "gatsby"

const Footer = () => {
  const [acceptCookies] = useState(Cookies.get("AllowSchickCookies") === "true")

  const removeCookies = () => {
    Cookies.remove("AllowSchickCookies")
    window.location.reload()
  }

  return (
    <div className="footer-wrapper">
      <div>
        <img src={Logo} alt="Rolf Schick GmbH Logo" />
      </div>
      <div>
        <h2>Kontakt</h2>
        <p>Rolf Schick GmbH</p>
        <p>Mäuswedel 13</p>
        <p>74223 Flein</p>
      </div>
      <div>
        <h2>Produkte</h2>
        <ul>
          <li>
            <Link to="/maultaschenteig">Maultaschenteig</Link>
          </li>
          <li>
            <Link to="/strudelteig">Strudelteig</Link>
          </li>
          <li>
            <Link to="/dinkelteig">Dinkelteig</Link>
          </li>
          <li>
            <Link to="/fruehlingsrollenteig">Frühlingsrollenteig</Link>
          </li>
          <li>
            <Link to="/muerbteig">Mürbteig</Link>
          </li>
          <li>
            <Link to="/blaetterteig">Blätterteig</Link>
          </li>
        </ul>
        <h2>Links</h2>
        <ul>
          <li>
            <Link to="/impressum">Impressum</Link>
          </li>
          <li>
            <Link to="/privacy">Datenschutz</Link>
          </li>
          <li>
            <Link to="#section_0">Nach oben</Link>
          </li>
          {acceptCookies && (
            <li>
              <a href="#top" onClick={() => removeCookies()}>
                Cookies ablehnen
              </a>
            </li>
          )}
        </ul>
      </div>
    </div>
  )
}

export default Footer

export const MobileFooter = () => {
  return (
    <div className="mobile-footer">
      <ul>
        <li>
          <Link to="/impressum">Impressum</Link>
        </li>
        <li>
          <Link to="/privacy">Datenschutz</Link>
        </li>
        <li>
          <Link to="#">Nach oben</Link>
        </li>
      </ul>
    </div>
  )
}
