export const GOOGLE_SITE_KEY = "6LfF0scUAAAAAIaHJ_oQBYJW1DwGuK-BBp13jwDr"

export const unloadRecaptcha = () => {
  const nodeBadge = document.querySelector(".grecaptcha-badge")
  if (nodeBadge) {
    document.body.removeChild(nodeBadge.parentNode)
  }

  const scriptSelector =
    "script[src='https://www.google.com/recaptcha/api.js?render=" +
    GOOGLE_SITE_KEY +
    "']"
  const script = document.querySelector(scriptSelector)
  if (script) {
    script.remove()
  }
}
